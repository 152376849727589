import React from "react";
import { Box, Center } from "Atoms";
import { Helmet } from "react-helmet-async";

/**
 * To change this, get the updated privacy policy at https://app.termly.io/dashboard/website/2dba249b-5916-4eb7-b23c-a7cbe8b9884d/privacy-policy
 * Until we get the Pro account, we have to download the html and then paste it above.
 * Then we iframe it in here to avoid manipulating it too much
 * */
const TermsAndConditions: React.FC<{}> = () => {
    return (
        <Center>
            {/* @ts-ignore*/}
            <Box
                as="div"
                // @ts-ignore
                name="termly-embed"
                style={{ width: "80vw" }}
                data-id="24ca8e1a-cf0e-44b5-8d5c-f084276cdb01"
                data-type="iframe"
            ></Box>
            {/** @ts-ignore */}
            <Helmet script={[{ src: "https://app.termly.io/embed-policy.min.js" }]} />
        </Center>
    );
};

export default TermsAndConditions;
