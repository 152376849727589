import React from "react";
import { Formik, Field, Form, FieldProps, ErrorMessage } from "formik-next";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";
import * as Yup from "yup";

import { Button, Input, Box, FormLabel, FormErrorMessage, Flex } from "Atoms";
import { useLanguage } from "Providers";
import { IconButton, InputGroup, InputRightElement, LinkButton, PasswordFormInput } from "Molecules";

export type UserAccountFormValues = {
    email: string;
    password: string;
};

type Props = {
    handleLogin: (values: UserAccountFormValues) => Promise<any>;
    shouldShowSignupButton?: boolean;
};

export const UserAccountLoginForm: React.FC<Props> = ({ handleLogin, shouldShowSignupButton = false }) => {
    const { translate, userLanguage } = useLanguage();

    const errorMessages = {
        sv: {
            email: "Måste vara en giltig email",
            required: "Obligatoriskt"
        },
        en: {
            email: "Must be a valid email",
            required: "Required"
        }
    };

    const { email, required } = errorMessages[userLanguage];

    const loginValidation = Yup.object().shape({
        email: Yup.string().email(email).required(required),
        password: Yup.string().required(required)
    });

    return (
        <Formik<UserAccountFormValues>
            validationSchema={loginValidation}
            initialValues={{
                email: "",
                password: ""
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                const res = await handleLogin(values);
                res && setErrors({ [res.path]: translate(res.message) });
                setSubmitting(false);
            }}
        >
            {props => (
                <Form>
                    <Field name="email">
                        {({ field, form }: FieldProps) => (
                            <Box mb={6}>
                                <FormLabel isRequired>Email</FormLabel>
                                <InputGroup size="lg">
                                    <Input
                                        pr="4.5rem"
                                        w="100%"
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        {...field}
                                    />
                                    {!!field.value.length && (
                                        <InputRightElement width="4.5rem">
                                            <IconButton
                                                tabIndex={-1}
                                                icon={IoIosClose}
                                                onClick={() => form.setFieldValue("email", "")}
                                                fontSize="22px"
                                                size="sm"
                                            />
                                        </InputRightElement>
                                    )}
                                </InputGroup>
                                <ErrorMessage name={field.name} component={FormErrorMessage} />
                            </Box>
                        )}
                    </Field>
                    <PasswordFormInput
                        name="password"
                        isMandatory
                        formLabel={translate("password")}
                        placeholder={translate("password")}
                        size="lg"
                        fullWidth
                    />
                    <Flex mt={4}>
                        <Button
                            fullWidth
                            isLoading={props.isSubmitting}
                            size="lg"
                            themeColor="green"
                            type="submit"
                            isDisabled={!props.isValid}
                        >
                            {translate("login")}
                        </Button>
                        {shouldShowSignupButton && (
                            <LinkButton to="/signup" ml={4} fullWidth size="lg" themeColor="gray">
                                {translate("signup")}
                            </LinkButton>
                        )}
                    </Flex>
                </Form>
            )}
        </Formik>
    );
};
