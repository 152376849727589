import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { delay } from "lodash";

import { Center, Fade, Text } from "Atoms";
import { OrderWindowStatus } from "Types";
import { useLanguage } from "Providers";
import { useOrderWindowSocketStore, useOrderWindowsStore } from "Stores";
import { Alert, AlertBody, AlertIcon, AlertTitle, LoadingDots } from "Molecules";
import { getCleanGroupOrderNickname } from "../utils";
import { createGroupOrderStatusPath } from "Utils";

type Props = {
    shouldOnlyShowLostWSConnection?: boolean;
} & PropsWithChildren;

type Params = {
    tableId: string;
    publicShopId: string;
    posId: string;
};

export const GroupOnlineOrderStatusOverlay: React.FC<Props> = ({ children, shouldOnlyShowLostWSConnection = true }) => {
    const [visibleAlert, setVisibleAlert] = useState<JSX.Element | undefined>(undefined);

    const params = useParams<Params>();

    const { orderStatus, hasLostWSConnection, nickname } = useOrderWindowSocketStore();
    const { translate, translateWithArgument } = useLanguage();

    const navigate = useNavigate();

    // Don't remove 'orderWindows', we need it for trigger zustand updates
    const { orderWindows, getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();

    const me = orderWindow?.customers?.find(customer => customer.nickname === nickname);

    const hasMyOrderBeenPaid = me?.paid;
    const isBeingPaidFor = !!me?.paidBy && me?.paidBy !== nickname;
    const cleanPayingCustomerNickname = getCleanGroupOrderNickname(me?.paidBy ?? "");

    useEffect(() => {
        if (hasMyOrderBeenPaid && !!orderWindow?.postponeOrderId && !!orderWindow?.id) {
            const url = createGroupOrderStatusPath(
                orderWindow.postponeOrderId,
                //@ts-ignore
                params.tableId,
                params.publicShopId,
                params.posId,
                orderWindow.id
            );
            delay(() => navigate(url, { state: { nickname }, replace: true }), 2000);
        }
    }, [hasMyOrderBeenPaid, orderWindow]);

    const HasBeenPaidForOverLay = () => (
        <Center h="full" w="full" direction="column">
            <Alert
                color="gray.800"
                position="absolute"
                zIndex="1500"
                status="success"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                width="100%"
            >
                <AlertIcon size="50px" mr={0} />
                <AlertTitle mt={4} mb={2} mr={0} fontSize="2xl">
                    {translateWithArgument("yourOrderIsPaid", cleanPayingCustomerNickname)}
                </AlertTitle>
                <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                    <Center direction="column" color="gray.800" fontSize="lg">
                        <Text as="span" display="flex">
                            {translate("weAreNavigatingYourForward")}
                            <LoadingDots numberOfDots={3} />
                        </Text>
                    </Center>
                </AlertBody>
            </Alert>
        </Center>
    );
    const BeingPaidForOverLay = () => (
        <Center h="full" w="full" direction="column">
            <Alert
                color="gray.800"
                position="absolute"
                zIndex="1500"
                status="info"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                width="100%"
            >
                <AlertIcon size="50px" mr={0} />
                <AlertTitle mt={4} mb={2} mr={0} fontSize="2xl">
                    {translateWithArgument("customerIsPayingYourOrder", cleanPayingCustomerNickname)}
                </AlertTitle>
                <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                    {translate("waitWhileYourOrderIsBeingPaid")}
                    <LoadingDots numberOfDots={3} mr={2} />
                </AlertBody>
            </Alert>
        </Center>
    );

    const LostConnectionOverlay = () => (
        <Center h="full" w="full" direction="column">
            <Alert
                color="gray.800"
                position="absolute"
                zIndex="1500"
                status="warning"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                width="100%"
            >
                <AlertIcon size="50px" mr={0} />
                <AlertTitle mt={4} mb={2} mr={0} fontSize="2xl">
                    {translate("lostConnectionWithTableTitle")}
                </AlertTitle>
                <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                    {translate("lostConnectionWithTableText")}
                    <LoadingDots numberOfDots={3} mr={2} />
                </AlertBody>
            </Alert>
        </Center>
    );
    const DoneOverlay = () => (
        <Center h="full" w="full" direction="column">
            <Alert
                color="gray.800"
                position="absolute"
                zIndex="1500"
                status="success"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                width="100%"
            >
                <AlertIcon size="50px" mr={0} />
                <AlertTitle mt={4} mb={2} mr={0} fontSize="2xl">
                    {translate("orderSent")}
                </AlertTitle>
                <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                    {translate("weAreNavigatingYourForward")}
                    <LoadingDots numberOfDots={3} mr={2} />
                </AlertBody>
            </Alert>
        </Center>
    );
    const PendingOverlay = () => (
        <Center h="full" w="full" direction="column">
            <Alert
                color="gray.800"
                position="absolute"
                zIndex="1500"
                status="info"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="100%"
                width="100%"
            >
                <AlertIcon size="50px" mr={0} />
                <AlertTitle mt={4} mb={2} mr={0} fontSize="2xl">
                    {translate("weAreSendingYourOrder")}
                </AlertTitle>
                <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                    {translate("soonYouWillHaveYourFood")}
                    <LoadingDots numberOfDots={3} mr={2} />
                </AlertBody>
            </Alert>
        </Center>
    );

    const isOrderStatusDone = orderStatus === OrderWindowStatus.DONE;
    const isOrderStatusPending = orderStatus === OrderWindowStatus.PENDING;

    const shouldShowFade =
        isOrderStatusDone || isOrderStatusPending || hasLostWSConnection || isBeingPaidFor || hasMyOrderBeenPaid;

    useEffect(() => {
        if (shouldShowFade) {
            setVisibleAlert(() => {
                if (hasLostWSConnection) {
                    return <LostConnectionOverlay />;
                } else if (isOrderStatusDone && !shouldOnlyShowLostWSConnection) {
                    return <DoneOverlay />;
                } else if (isOrderStatusPending && !shouldOnlyShowLostWSConnection) {
                    return <PendingOverlay />;
                } else if (hasMyOrderBeenPaid) {
                    return <HasBeenPaidForOverLay />;
                } else if (isBeingPaidFor) {
                    return <BeingPaidForOverLay />;
                }
            });
        }
    }, [shouldShowFade, hasMyOrderBeenPaid, isOrderStatusDone, isOrderStatusPending]);

    if (shouldShowFade) {
        return (
            <Fade in={shouldShowFade} style={{ zIndex: 1500 }}>
                {visibleAlert}
            </Fade>
        );
    } else {
        return <>{children}</>;
    }
};
