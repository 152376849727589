import React from "react";
import { Form, Formik } from "formik-next";
import moment from "moment";

import { UserSubscriptionDTO } from "Types";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, ModalActions, FormInput } from "Molecules";
import { Box, Button, Flex, Text, Textarea } from "Atoms";
import { IModalContext, Languagekey, useLanguage } from "Providers";
import { modalNames } from "Constants";

type Props = {
    userSubscription: UserSubscriptionDTO;
    handleUnsubscribe: (
        subscription: UserSubscriptionDTO,
        reasons: { reason: string; detailedReason: string; cancelledTimestamp: number }
    ) => void;
};

export const preWrittenCancelReasons: Languagekey[] = [
    "cancelReasonCostsTooMuch",
    "cancelReasonCostsDontKnow",
    "cancelReasonCostsTooComplex",
    "cancelReasonCostsLimitedSelection",
    "cancelReasonCostsDidnotMeetExpectations",
    "cancelReasonCostsRelocation",
    "cancelReasonCostsVacation"
];

export const UserAccountCancelSubscriptionModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();
    const { userSubscription, handleUnsubscribe } = modalContent;

    const onCloseModal = () => closeModal(modalNames.USER_ACCOUNT_UNSUBSCRIBE_MODAL);

    const { subscription, chosenPricePlan } = userSubscription;
    const nextPaymentMoment = moment
        .utc(userSubscription.latestRecurringPaymentTimestamp)
        .add(chosenPricePlan.paymentFrequencyInMonths, "months");

    const timeUntilNextPayment = nextPaymentMoment.diff(moment(), "days");
    const nextPaymentDateString = nextPaymentMoment.format("YYYY-MM-DD");

    return (
        <Modal size="xs" open onClose={onCloseModal} overlayProps={{ zIndex: 1400 }}>
            <ModalHeader fontSize="2xl">
                {translate("wantToQuit")} {subscription.name}?
                <ModalCloseBtn />
            </ModalHeader>
            <Formik
                initialValues={{
                    reason: "",
                    detailedReason: "",
                    hasProceed: false
                }}
                onSubmit={({ reason, detailedReason }) =>
                    handleUnsubscribe(userSubscription, {
                        reason,
                        detailedReason,
                        cancelledTimestamp: new Date().getTime()
                    })
                }
            >
                {({ values: { hasProceed, detailedReason, reason }, setFieldValue, handleSubmit }) => {
                    const onProceed = () => setFieldValue("hasProceed", true);
                    const hasReason = !!reason || !!detailedReason;
                    return (
                        <>
                            <ModalBody>
                                <Box>
                                    <Form>
                                        {!hasProceed ? (
                                            <Box>
                                                <Text fontSize="xl" textAlign="center">
                                                    <b>{`${timeUntilNextPayment} ${translate("days")} `}</b>
                                                    {translate("cancelSubscriptionText")}.
                                                    <br />
                                                    {translate("nextBillingDay")} <b>{nextPaymentDateString}</b>.
                                                </Text>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Flex wrap="wrap" h="auto" mb={4}>
                                                    {preWrittenCancelReasons.map(preWrittenReason => {
                                                        let styles = {
                                                            borderColor: "gray.600",
                                                            color: "gray.600",
                                                            backgroundColor: "transparent"
                                                        };
                                                        const isSelected = preWrittenReason === reason;
                                                        if (isSelected) {
                                                            styles = {
                                                                borderColor: "blue.600",
                                                                color: "blue.600",
                                                                backgroundColor: "blue.200"
                                                            };
                                                        }
                                                        return (
                                                            <Button
                                                                key={preWrittenReason}
                                                                size="sm"
                                                                variant="outline"
                                                                fontWeight="normal"
                                                                onClick={() =>
                                                                    setFieldValue("reason", preWrittenReason)
                                                                }
                                                                mr={2}
                                                                mb={2}
                                                                {...styles}
                                                            >
                                                                {translate(preWrittenReason)}
                                                            </Button>
                                                        );
                                                    })}
                                                </Flex>

                                                <FormInput
                                                    name="detailedReason"
                                                    formLabel={translate("whyCancelingSubscription")}
                                                    helperText={`${translate(
                                                        "stillHave"
                                                    )} ${timeUntilNextPayment} ${translate("days")} ${translate(
                                                        "cancelSubscriptionText"
                                                    )}`}
                                                    component={Textarea}
                                                    fullWidth
                                                    wrapperProps={{ mb: 0 }}
                                                    resize="none"
                                                />
                                            </Box>
                                        )}
                                    </Form>
                                </Box>
                            </ModalBody>
                            <ModalActions display="flex" justifyContent="flex-end">
                                <Button
                                    type={hasProceed ? "submit" : "button"}
                                    themeColor="red"
                                    disabled={hasProceed && !hasReason}
                                    onClick={() => (hasProceed ? handleSubmit() : onProceed())}
                                >
                                    {translate("cancelAlt")}
                                </Button>
                            </ModalActions>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};
