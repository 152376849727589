import { useIdleTimer, IdleTimerProps } from "react-idle-timer";

import { ILanguages } from "Providers";
import { autoCloseTimerNotification } from "TempUtils";

type UseOnlineExpressIdleTimerOptions = {
    onClose: () => void;
    timeout?: number;
    swalDuration?: number;
    startManually?: boolean;
} & IdleTimerProps;

const multiLang = {
    en: {
        title: "You've been idle for too long. Press anywhere to continue.",
        text: "You get to start over within "
    },
    sv: {
        title: "Du har varit inaktiv för länge. Tryck någonstans för att fortsätta.",
        text: "Du får börja om inom "
    }
};
export const useOnlineExpressIdleTimer = (
    userLanguage: ILanguages,
    { onClose, swalDuration = 10000, timeout = 60000, startManually = false }: UseOnlineExpressIdleTimerOptions
) => {
    const handleOnIdle = () => {
        autoCloseTimerNotification(
            multiLang[userLanguage].title,
            `${multiLang[userLanguage].text} <strong></strong> s`,
            swalDuration,
            onClose
        );
    };
    return useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        debounce: 500,
        startManually
    });
};
