import React from "react";
import moment from "moment";
import Truncate from "react-truncate";
import { RiInformationLine } from "@react-icons/all-files/ri/RiInformationLine";

import {
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Drawer,
    DrawerOverlay,
    DrawerActions
} from "Organisms";
import {
    GOOGLE_ANALYTICS_EVENT_ACTION,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    SubscriptionStatus,
    SubscriptionType,
    UserSubscriptionDTO
} from "Types";
import { Box, Button, Flex, Header, Image, Text, ToolTip } from "Atoms";
import { IDrawerComponentContext, modals, useLanguage, useModal } from "Providers";
import { GET_POSSIBLE_UPGRADES_FROM_USER_SUBSCRIPTION } from "GraphQLQueries";
import { gaEvent } from "Utils";
import { useMothershipQuery } from "Hooks";
import { OnlineShopSelector } from "OnlineComponents";
import { UserAccountSubscriptionStatusLabel } from "./UserAccountSubscriptionStatusLabel";
import { SubscriptionProducts } from "../../../../../shared";

type Props = {
    userSubscription: UserSubscriptionDTO;
    onOpenModal: (userSubscription: UserSubscriptionDTO) => void;
    handleResumeSubscription: (userSubscription: UserSubscriptionDTO) => void;
};

export const UserAccountSubscriptionDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { userSubscription, onOpenModal, handleResumeSubscription },
    routerProps,
    onCloseDrawer
}) => {
    const { openModal } = useModal();
    const { navigate } = routerProps || {};
    const { status, subscription, creditCardInfo, chosenPricePlan, subscriptionCancelReason, subscriptionType } =
        userSubscription;
    const { data: getPossibleUpgradesFromUserSubscriptionData } = useMothershipQuery(
        GET_POSSIBLE_UPGRADES_FROM_USER_SUBSCRIPTION,
        { variables: { userSubscriptionId: userSubscription.id } }
    );
    const availableSubscriptionUpgrades =
        getPossibleUpgradesFromUserSubscriptionData?.getPossibleUpgradesFromUserSubscription;

    const { translate, translateWithArgument } = useLanguage();
    const isPendingCancellation = !!subscriptionCancelReason;
    // These are hardcoded Bröd and Salt subscriptions which have been discontinued.
    //  They are no longer available and a migration has been done in the DB to set them to cancelled.
    //  Therefore, we do NOT want the users to be able to resume them.
    const discontinuedSubscriptionIds = new Set([
        "61eec115f344271558cd60bd",
        "640b1a766a607243c28a78b5",
        "623870359654ce7b53678aaf",
        "6238724a9654ce7b53678ab1",
        "62387ba29654ce7b53678ab2"
    ]);
    const isResumeable = isPendingCancellation && !discontinuedSubscriptionIds.has(userSubscription.subscriptionId);
    const nextPaymentMoment = moment
        .utc(userSubscription.latestRecurringPaymentTimestamp)
        .add(chosenPricePlan.paymentFrequencyInMonths, "months");
    const willBeCancelled =
        status == SubscriptionStatus.ACTIVE && subscriptionCancelReason && subscriptionCancelReason.cancelledTimestamp;
    const isGroupSubscription = subscriptionType == SubscriptionType.GROUP;

    const isMoreThanThreeMonths = moment().diff(moment(userSubscription.createdAt), "months") >= 3;
    const canCancelAfter = moment(userSubscription.createdAt).add(3, "months").format("YYYY-MM-DD");
    const cancelSubscriptionTooltip: JSX.Element = (
        <Box maxWidth="15rem">{translateWithArgument("threeMonthsBinding", canCancelAfter)}</Box>
    );

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb={4}>
                    <Box>
                        <UserAccountSubscriptionStatusLabel userSubscription={userSubscription} />
                        <Flex justify="space-between" h="auto">
                            <Header as="h3" m="0">
                                {subscription.name}
                            </Header>
                        </Flex>
                    </Box>
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <Flex direction="column">
                        {subscription.imageUrl && (
                            <Image src={subscription.imageUrl} rounded="lg" maxH="175px" objectFit="cover" />
                        )}
                        <Text fontSize="lg" mt={4}>
                            {/** @ts-ignore */}
                            <Truncate lines={2}> {subscription.description}</Truncate>
                        </Text>
                        <Box pb={4} pt={4}>
                            <Text mb={1} fontSize="md" fontWeight="bold">
                                {translate("choose")} {translate("restaurant")}:
                            </Text>
                            <OnlineShopSelector
                                shopIds={subscription.shopIds}
                                preferredShopId={userSubscription.preferredShopId}
                                navigate={navigate!}
                                onClick={onCloseDrawer}
                            />
                        </Box>
                        <Box pb={4} pt={4}>
                            <Text mb={1} fontSize="md" fontWeight="bold">
                                {translate("productsIncludedIn")} {subscription.name}:
                            </Text>
                            <SubscriptionProducts subscriptionProducts={subscription.subscriptionProducts} />
                        </Box>
                    </Flex>
                </DrawerBody>
                {!isGroupSubscription && (
                    <DrawerActions>
                        <Flex h="auto" justify="space-between">
                            <Text fontWeight="bold">{translate("card")}:</Text>
                            <Text>
                                <Text as="span">
                                    {creditCardInfo.cardIssuer} {creditCardInfo.maskedPAN}
                                </Text>
                            </Text>
                        </Flex>
                        {!willBeCancelled && (
                            <Flex h="auto" justify="space-between">
                                <Text fontWeight="bold">{translate("paymentAmount")}:</Text>

                                <Text>
                                    <Text as="span"> {chosenPricePlan.amount} kr</Text>
                                </Text>
                            </Flex>
                        )}

                        <Flex h="auto" justify="space-between">
                            {willBeCancelled ? (
                                <Text fontWeight="bold">{translate("subscriptionExpires")}:</Text>
                            ) : (
                                <Text fontWeight="bold">{translate("nextPayment")}:</Text>
                            )}
                            <Text>
                                <Text as="span">{nextPaymentMoment.format("YYYY-MM-DD")}</Text>
                            </Text>
                        </Flex>
                        <Flex direction={"row"} justify="space-between">
                            <Flex h="auto" justify="flex-start">
                                {isResumeable && (
                                    <Button
                                        themeColor="green"
                                        variant="outline"
                                        onClick={() => handleResumeSubscription(userSubscription)}
                                    >
                                        {translate("reactivateSubscription")}
                                    </Button>
                                )}

                                {!subscriptionCancelReason && (
                                    <Flex w="100%" justifyContent={"flex-start"}>
                                        <Button
                                            themeColor="red"
                                            variant="outline"
                                            disabled={!isMoreThanThreeMonths}
                                            onClick={() => {
                                                gaEvent({
                                                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.SUBSCRIPTION,
                                                    action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
                                                    label: "Open cancel modal"
                                                });
                                                onOpenModal(userSubscription);
                                            }}
                                        >
                                            {translate("cancelSubscription")}
                                        </Button>
                                        {!isMoreThanThreeMonths && (
                                            <ToolTip
                                                placement="bottom"
                                                content={cancelSubscriptionTooltip as any}
                                                w="1.5rem"
                                                ml={2}
                                            >
                                                <Box as={RiInformationLine} size="2rem" color="blue.400" />
                                            </ToolTip>
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                            {!!availableSubscriptionUpgrades?.length && !isPendingCancellation && (
                                <Button
                                    themeColor="green"
                                    onClick={async () => {
                                        gaEvent({
                                            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.SUBSCRIPTION,
                                            action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
                                            label: "Open upgrade modal"
                                        });
                                        openModal(modals.subscriptionUpgradeModal, {
                                            currentUserSubscription: userSubscription,
                                            availableSubscriptionUpgrades,
                                            onModalClosed: () => {}
                                        });
                                    }}
                                >
                                    {translate("upgradeSubscription")}
                                </Button>
                            )}
                        </Flex>
                    </DrawerActions>
                )}
                <Flex h="auto" direction="row">
                    <Text width="100%" textAlign="center">
                        {translate("contactBSSubscriptionQuestions")}
                    </Text>
                </Flex>
            </DrawerContent>
        </Drawer>
    );
};
