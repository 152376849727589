import React from "react";

import { Text, BaseBoxProps, Box } from "Atoms";

type Props = {
    logoStyles: BaseBoxProps;
    textStyles: BaseBoxProps;
};

export const PoweredByQoplaLogo: React.FC<Props> = ({ logoStyles, textStyles }) => {
    return (
        <Box position={"relative"} zIndex={2}>
            <Text fontFamily="qopla" color="#414340" mb={0} {...logoStyles}>
                Qopla
            </Text>
            <Text position="absolute" right={0} bottom="2px" {...textStyles}>
                Powered by
            </Text>
        </Box>
    );
};
