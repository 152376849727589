import React from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { IoIosRemove } from "@react-icons/all-files/io/IoIosRemove";
import { IoIosAdd } from "@react-icons/all-files/io/IoIosAdd";
import { AiOutlineDisconnect } from "@react-icons/all-files/ai/AiOutlineDisconnect";

import { BaseBoxProps, Box, Header, Flex, Button, Stack, Text, Center, Collapsable, Fade, Label } from "Atoms";
import { BundleProductItems, ModificationsList, OnlineAddonsList } from "Organisms";
import { FullOrderWindowCustomer, getCartProductsByCustomerMetaStatus, useLanguage } from "Providers";
import { useOrderWindowSocketStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import { formatFinancialNumbers, getCartTotalPrice } from "Utils";
import { CartProduct, CustomerMetaStatus, OrderWindowCustomerStatus } from "Types";
import { useSwitch } from "Hooks";
import { IconButton } from "Molecules";

type Props = {
    customer: FullOrderWindowCustomer;
    showPayment?: boolean;
    isCheckout?: boolean;
    isInGroupOrder?: boolean;
    statusToShow: CustomerMetaStatus;
    onPayClick?: (nickname: string) => void;
} & BaseBoxProps;

export const GroupOnlineCustomerSummary: React.FC<Props> = ({
    customer,
    showPayment = false,
    isCheckout = false,
    isInGroupOrder = false,
    statusToShow,
    onPayClick,
    children,
    ...rest
}) => {
    const { translate } = useLanguage();

    const { companyLocale } = useQoplaStore();
    const { nickname } = useOrderWindowSocketStore();
    const { incrementOrderProduct, decrementOrderProduct, removeCartProduct } = useOrderWindowsStore();

    const isMe = customer?.nickname === nickname;
    const isDisconnected = customer?.status === OrderWindowCustomerStatus.DISCONNECTED;

    const hasPaid = customer.paid;
    const isPaying = !!customer.paidBy && !customer.paid;

    let cartProducts: CartProduct[] = [];
    if (hasPaid) {
        cartProducts = [];
    } else if (isPaying && !isCheckout) {
        cartProducts = [];
    } else {
        cartProducts = getCartProductsByCustomerMetaStatus(customer?.cartProducts ?? [], statusToShow);
    }

    const totalAmount = getCartTotalPrice(cartProducts);

    const shouldShowPayButton = isMe && showPayment && totalAmount > 0;
    const numCartProducts = cartProducts.length;
    const shouldShowFade = isMe && !showPayment && numCartProducts > 3;

    const shouldShowReadyFlag =
        isInGroupOrder && !showPayment && customer.status === OrderWindowCustomerStatus.READY_TO_ORDER;

    const { open, onToggle } = useSwitch(isMe && !shouldShowFade);

    if (!customer) {
        return <></>;
    }

    return (
        <Box {...rest}>
            <Flex justify="space-between" align="center" h="auto" onClick={() => !isMe && onToggle()} mb={4}>
                <Stack isInline align="center" stretch={2}>
                    <Stack isInline align="center" stretch={1} color={isDisconnected ? "red.500" : "gray.900"}>
                        {isDisconnected && <Box as={AiOutlineDisconnect} fontSize="xl" />}
                        <Header fontWeight={isMe ? 700 : 500} as="h4" size="lg" m="0" textTransform="capitalize">
                            {isMe ? `${customer.cleanNickname} (${translate("youOrder")})` : customer.cleanNickname}
                        </Header>
                    </Stack>
                    {shouldShowReadyFlag && (
                        <Label themeColor="green" minH="auto" rounded="lg">
                            {translate("ready")}
                        </Label>
                    )}
                </Stack>
                <Stack isInline align="center" stretch={2} color={isDisconnected ? "red.500" : "gray.900"}>
                    <Header fontWeight={isMe ? 700 : 500} as="h4" size="lg" m="0">
                        {formatFinancialNumbers(totalAmount, companyLocale)}
                    </Header>
                    {!isMe && <Box as={open ? FaChevronUp : FaChevronDown} />}
                </Stack>
            </Flex>
            <Collapsable open={open} height={shouldShowFade ? 200 : 0} style={{ position: "relative" }}>
                <Box>
                    {shouldShowFade && (
                        <Fade in={!open}>
                            <Button
                                position="absolute"
                                bottom="0"
                                left="0"
                                right="0"
                                size="lg"
                                themeColor="blue"
                                variant="link"
                                onClick={onToggle}
                                zIndex="10"
                            >
                                {translate("showAllProducts")}
                            </Button>
                            <Box
                                position="absolute"
                                bottom="0"
                                display="block"
                                width="100%"
                                height="100%"
                                backgroundImage="linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9) 100%)"
                            />
                        </Fade>
                    )}
                    <Stack stretch={6}>
                        {cartProducts.map(cartProduct => (
                            <Box key={cartProduct.id}>
                                <Flex h="auto" justify="space-between" align="center">
                                    <Stack isInline stretch={2} align="center">
                                        {open && isMe && !showPayment && (
                                            <IconButton
                                                size="md"
                                                rounded="lg"
                                                fontSize="2xl"
                                                icon={IoIosRemove}
                                                onClick={() => {
                                                    if (cartProduct.orderProduct.quantity > 1) {
                                                        decrementOrderProduct(cartProduct);
                                                    } else {
                                                        removeCartProduct(cartProduct);
                                                    }
                                                }}
                                            />
                                        )}
                                        <Text fontSize="lg" color="gray.900" as="span">
                                            {cartProduct.orderProduct.quantity}x
                                        </Text>
                                        {open && isMe && !showPayment && (
                                            <IconButton
                                                size="md"
                                                rounded="lg"
                                                fontSize="2xl"
                                                icon={IoIosAdd}
                                                onClick={() => incrementOrderProduct(cartProduct)}
                                            />
                                        )}
                                        <Text fontSize="lg" m="0" color="gray.900" as="span">
                                            {cartProduct.orderProduct.name}{" "}
                                        </Text>
                                    </Stack>
                                    <Text fontSize="lg" m="0" color="gray.900" as="span">
                                        {formatFinancialNumbers(cartProduct.orderProduct.totalPrice, companyLocale)}
                                    </Text>
                                </Flex>
                                <Box ml={3}>
                                    <OnlineAddonsList mb={2} addons={cartProduct.orderProduct.addons} />
                                    {cartProduct.orderProduct.selectedBundleProductItems && (
                                        <BundleProductItems
                                            bundleProductItems={cartProduct.orderProduct.selectedBundleProductItems}
                                        />
                                    )}
                                    {cartProduct.orderProduct.modifications && (
                                        <ModificationsList
                                            modifications={cartProduct.orderProduct.modifications as any}
                                        />
                                    )}
                                </Box>
                                {cartProduct.orderProduct.comment && (
                                    <Box rounded="lg" p={2} mt={2} wordBreak="break-word">
                                        <Text fontStyle="italic" color="gray.600">
                                            {cartProduct.orderProduct.comment}
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Stack>
                    {shouldShowPayButton && !isCheckout && (
                        <Center position="sticky" background="white" bottom="0" h="auto" mt={2}>
                            <Button
                                themeColor="blue"
                                rounded="lg"
                                onClick={() => onPayClick && onPayClick(nickname as string)}
                            >
                                {translate("pay")} {formatFinancialNumbers(totalAmount, companyLocale)}
                            </Button>
                        </Center>
                    )}
                </Box>
            </Collapsable>
        </Box>
    );
};
