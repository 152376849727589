import React from "react";
import moment from "moment";

import { Label } from "Atoms";
import { SubscriptionStatus, UserSubscriptionDTO } from "Types";
import { useLanguage } from "Providers";

const subscriptionStatusToText: Partial<Record<SubscriptionStatus, any>> = {
    ACTIVE: {
        translationKey: "subscriptionStatusActive",
        backgroundColor: "green.200",
        color: "green.800"
    },
    CANCELLED: {
        translationKey: "subscriptionStatusCancelled",
        backgroundColor: "red.200",
        color: "red.800"
    },
    AT_RISK: {
        translationKey: "subscriptionStatusMissingPayment",
        backgroundColor: "orange.200",
        color: "orange.800"
    },
    PENDING: {
        translationKey: "subscriptionStatusPending",
        backgroundColor: "yellow.200",
        color: "yellow.800"
    },
    INITIAL_PAYMENT_FAILED: {
        translationKey: "subscriptionStatusPaymentFailed",
        backgroundColor: "red.200",
        color: "red.800"
    }
};

type Props = {
    userSubscription: UserSubscriptionDTO;
};

export const UserAccountSubscriptionStatusLabel: React.FC<Props> = ({ userSubscription }) => {
    const { translate } = useLanguage();
    const { latestRecurringPaymentTimestamp, subscriptionCancelReason, chosenPricePlan, status } = userSubscription;
    const isPendingCancellation = !!subscriptionCancelReason;
    const statusUsedForStyling = isPendingCancellation ? SubscriptionStatus.PENDING : status;

    const { translationKey, ...styles } = subscriptionStatusToText[statusUsedForStyling];
    let text = translate(translationKey);

    if (isPendingCancellation) {
        const nextPaymentMoment = moment
            .utc(latestRecurringPaymentTimestamp)
            .add(chosenPricePlan.paymentFrequencyInMonths, "months");

        text = `${translate("subscriptionStatusActiveUntil")} ${nextPaymentMoment.format("YYYY-MM-DD")}`;
    }

    return (
        <Label
            display="inline-block"
            minH="auto"
            textTransform="uppercase"
            fontWeight="bold"
            themeColor="green"
            _hover={undefined}
            mb={2}
            {...styles}
        >
            {text}
        </Label>
    );
};
