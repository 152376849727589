import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik-next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Text, FormLabel, FormErrorMessage } from "Atoms";
import { useLanguage } from "Providers";
import { appendDefaultCountryCodeIfMissing, hasZeroAfterDialCode, isValidLengthPhoneNumber } from "Utils";

type Props = {
    phoneNumber: string;
    name: string;
    inputLabel?: string;
    isMandatory?: boolean;
    relatedSwishField?: string;
    countryCode?: string;
    onChangeCountryCode?: (countryCode: string) => void;
};

type phoneNumberType = "phoneNumber" | "phoneNumber2";

type PhoneNumberInputType = {
    countryCode: string | undefined;
    dialCode: string | undefined;
    phoneNumber: string;
    phoneNumber2: string;
    swishNumber: string;
};

const DEFAULT_INPUT_MASK = ".. ... .. .. ..";

export const PhoneInputWithCountryCode: FC<Props> = ({
    phoneNumber,
    inputLabel,
    isMandatory = false,
    name,
    relatedSwishField = ""
}) => {
    const { errors, values, setFieldValue, setErrors, setFieldTouched, isValid } =
        useFormikContext<PhoneNumberInputType>();
    const { translateWithArgument } = useLanguage();
    //@ts-ignore
    const [country, setCountry] = useState({ countryCode: new Intl.Locale("sv-SE").region, dialCode: "46" });

    useEffect(() => {
        if (values.countryCode && values.countryCode !== country.countryCode) {
            setCountry({ countryCode: values.countryCode, dialCode: values.dialCode! });
        }
    }, [values.countryCode]);

    useEffect(() => {
        const hasCountryCode = phoneNumber.startsWith(country.dialCode);
        const phoneNumberWithoutCountryCode = hasCountryCode ? phoneNumber.slice(2) : phoneNumber;
        if (isMandatory || (hasCountryCode && phoneNumberWithoutCountryCode!.length > 0)) {
            const hasPhoneInputErrors = !!errors[name as phoneNumberType];
            if (
                !hasPhoneInputErrors &&
                (!isValidLengthPhoneNumber(phoneNumber) || hasZeroAfterDialCode(phoneNumber, country.dialCode))
            ) {
                const errorMessage = hasZeroAfterDialCode(phoneNumber, country.dialCode)
                    ? translateWithArgument("zeroAfterDialCodeWithArg", country.dialCode)
                    : translateWithArgument("phoneNumberErrorWithArg", country.dialCode);
                setErrors({
                    ...errors,
                    [name]: errorMessage
                });
            } else if (
                isValidLengthPhoneNumber(phoneNumber) &&
                !hasZeroAfterDialCode(phoneNumber, country.dialCode) &&
                hasPhoneInputErrors
            ) {
                const updatedErrors = { ...errors };
                delete updatedErrors[name as phoneNumberType];
                setErrors(updatedErrors);
            }
        }
    });

    const onPhoneChange = (phone: string, newCountryCode: string, dialCode: string) => {
        const updatedPhoneNumber = appendDefaultCountryCodeIfMissing(phone);
        setFieldValue(name, updatedPhoneNumber);

        if (relatedSwishField) {
            setFieldValue(relatedSwishField, phone);
            if (newCountryCode !== country.countryCode) {
                setFieldValue("countryCode", newCountryCode);
                setFieldValue("dialCode", dialCode);
            }
        }

        setFieldTouched(name, true);
        updateCountryCode(newCountryCode, dialCode);
    };
    const updateCountryCode = (newCountryCode: string, dialCode: string) => {
        if (newCountryCode !== country.countryCode) {
            setCountry({ countryCode: newCountryCode, dialCode: dialCode });
        }
    };

    const phoneInputError = errors[name as phoneNumberType];

    return (
        <>
            <FormLabel>
                {inputLabel}
                {isMandatory && (
                    <Text as="span" color="red.500" ml={2}>
                        *
                    </Text>
                )}
            </FormLabel>
            <PhoneInput
                inputProps={{ name }}
                value={phoneNumber}
                country={"se"}
                masks={{ se: DEFAULT_INPUT_MASK }}
                placeholder="+46 000000000"
                onChange={(phone: string, country: any) => {
                    onPhoneChange(phone, country.countryCode.toUpperCase(), country.dialCode);
                }}
                autocompleteSearch={true}
                inputStyle={{
                    width: "100%",
                    height: "3rem",
                    border: "1px solid #EDF2F7",
                    fontSize: "1.125rem"
                }}
                onMount={(_: string, country: any) => {
                    updateCountryCode(country.countryCode.toUpperCase(), country.dialCode);
                }}
                buttonStyle={{
                    border: "1px solid #EDF2F7"
                }}
                countryCodeEditable={false}
            />
            {!isValid && <FormErrorMessage>{phoneInputError}</FormErrorMessage>}
        </>
    );
};
