import React, { useEffect } from "react";

import { Box, Button, Flex, Image, Link, Text } from "Atoms";
import { SubscriptionStatus, SubscriptionType, UserSubscriptionDTO } from "Types";
import { UserAccountSubscriptionStatusLabel } from "./UserAccountSubscriptionStatusLabel";
import { GET_GROUP_SUBSCRIPTION_CONTACT_INFORMATION_BY_USER_SUBSCRIPTION_ID } from "GraphQLQueries";
import { useMothershipLazyQuery } from "Hooks";
import { useLanguage } from "Providers";

type Props = {
    userSubscription: UserSubscriptionDTO;
    onOpenDrawer: () => void;
};

export const UserAccountSubscriptionRow: React.FC<Props> = ({ userSubscription, onOpenDrawer, ...rest }) => {
    const [fetchGroupSubscriptionContactInformation, { data: groupSubscriptionContactInformation }] =
        useMothershipLazyQuery(GET_GROUP_SUBSCRIPTION_CONTACT_INFORMATION_BY_USER_SUBSCRIPTION_ID, {
            fetchPolicy: "network-only"
        });
    const { translate } = useLanguage();

    const { subscription } = userSubscription;
    const hasImage = !!subscription.imageUrl;
    const isGroupSubscription = userSubscription.subscriptionType == SubscriptionType.GROUP;
    const contactEmail =
        groupSubscriptionContactInformation?.getGroupSubscriptionContactInformationByUserSubscriptionId?.contactEmail;

    useEffect(() => {
        if (isGroupSubscription) {
            fetchGroupSubscriptionContactInformation({ variables: { userSubscriptionId: userSubscription.id } });
        }
    }, []);

    const onButtonClick = () => {
        if (userSubscription.status === SubscriptionStatus.ACTIVE) {
            onOpenDrawer();
        }
    };
    return (
        <Button
            key={subscription!.id}
            onClick={onButtonClick}
            bg="white"
            h="full"
            p="0"
            _hover={{ cursor: "pointer" }}
            boxShadow="md"
            rounded="lg"
            minH={12}
            {...rest}
        >
            <Flex h="auto" w="full" justify="space-between" align="center">
                <Flex h="auto" align="center">
                    {hasImage && (
                        <Image
                            src={subscription.imageUrl}
                            maxW={[32, 40]}
                            h="100px"
                            w="100px"
                            objectFit="cover"
                            roundedLeft="lg"
                        />
                    )}
                    <Flex direction="column" align="flex-start" ml={hasImage ? 2 : 0} p={[2, 4]}>
                        <UserAccountSubscriptionStatusLabel userSubscription={userSubscription} />
                        <Text fontSize={["lg", "xl"]} fontWeight="bold" mb="0">
                            {subscription!.name}
                        </Text>
                        {isGroupSubscription && contactEmail && (
                            <Flex lineHeight="tall" py={1} fontSize="sm" fontWeight="normal">
                                <Text fontSize="sm" mb={2}>
                                    {translate("contactInformation")}:
                                    <Link href={`mailto:${contactEmail}`} color="blue.500" ml={1}>
                                        {contactEmail}
                                    </Link>
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Button>
    );
};
