import React from "react";
import { useQuery } from "react-apollo";

import { Link, List, ListItem, Skeleton, Stack } from "Atoms";
import { GET_PUBLIC_SHOP_INFOS_BY_IDS } from "GraphQLQueries";
import { formatShopUrlName } from "Utils";

type Props = {
    ids: string[];
};

export const SubscriptionStatusContainerShops: React.FC<Props> = ({ ids }) => {
    const { data, loading } = useQuery(GET_PUBLIC_SHOP_INFOS_BY_IDS, {
        variables: { shopIds: ids }
    });

    if (loading) {
        return (
            <Stack stretch={2}>
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" />
            </Stack>
        );
    }

    const subscriptionShops: { name: string; id: string; publicId: string }[] = data.getPublicShopInfosByIds;

    return (
        <List listStyle="none" stretch={2} ml={2}>
            {subscriptionShops.map(shop => {
                const to = `/restaurant/${formatShopUrlName(shop.name)}/${shop.publicId}/order`;
                return (
                    <ListItem key={shop.name}>
                        <Link as={"a"} color="blue.500" href={to} target="_blank">
                            {shop.name}
                        </Link>
                    </ListItem>
                );
            })}
        </List>
    );
};
