import React from "react";

import { DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, Drawer, DrawerOverlay } from "Organisms";
import { UserGiftCard, Shop } from "Types";
import { Box, Header, Skeleton, Stack, Text } from "Atoms";
import { useMothershipQuery } from "Hooks";
import { GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID } from "GraphQLQueries";
import { IDrawerComponentContext, useLanguage } from "Providers";
import { UserGiftCardInformation } from "../../../giftCardStatusContainer/components/UserGiftCardInformation";
import { GiftCardLegal } from "./GiftCardLegal";
import { OnlineShopSelector } from "OnlineComponents";

type Props = {
    userGiftCard: UserGiftCard;
    publicShops: Shop[] | [];
    showOnlyInfo?: boolean;
};

export const UserAccountGiftCardDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { userGiftCard, showOnlyInfo = false },
    routerProps,
    onCloseDrawer
}) => {
    const { translate } = useLanguage();

    const { navigate } = routerProps || {};

    const shouldFetchCompanyShopIds =
        !!userGiftCard.giftCardSettings.companyId && !userGiftCard.giftCardSettings.shopIds.length;

    const { data } = useMothershipQuery(GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID, {
        variables: {
            companyId: userGiftCard.giftCardSettings.companyId
        },
        skip: !shouldFetchCompanyShopIds
    });

    const shopIds: string[] = shouldFetchCompanyShopIds
        ? data?.getShopsPublicInfoByCompanyId?.map((shopInfo: any) => shopInfo.id)
        : userGiftCard?.giftCardSettings?.shopIds;

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb={4}>
                    <Header as="h3" m="0">
                        {translate("giftCard")}
                    </Header>
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <Box height="auto">
                        <UserGiftCardInformation
                            userGiftCard={userGiftCard}
                            giftCardSettings={userGiftCard.giftCardSettings}
                        />
                    </Box>
                    <GiftCardLegal />
                    {!!shopIds?.length ? (
                        <Stack stretch={1} h="auto">
                            <Text fontSize="md" fontWeight="bold">
                                {translate("choose")} {translate("restaurant")}:
                            </Text>
                            <OnlineShopSelector shopIds={shopIds} onClick={onCloseDrawer} navigate={navigate!} />
                        </Stack>
                    ) : (
                        <Skeleton h="40px" />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
