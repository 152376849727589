import React, { useState } from "react";
import { flatMap, isEqual } from "lodash";
import { FaArrowDown } from "@react-icons/all-files/fa/FaArrowDown";
import { toast } from "react-toastify";

import {
    GOOGLE_ANALYTICS_EVENT_ACTION,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    PublicShopInfo,
    Subscription,
    UserSubscriptionDTO
} from "Types";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, ModalActions } from "Molecules";
import { Box, Button, Center, Collapsable, ExpandableList, Flex, Link, Stack, Text, Truncate } from "Atoms";
import { IModalContext, modals, useLanguage } from "Providers";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { UPGRADE_USER_SUBSCRIPTION } from "GraphQLMutations";
import { SubscriptionProducts } from "../../../../../../customer/shared";
import { colors } from "../../../../../../theme/colors";
import { LanguageText } from "Components";
import { GET_PUBLIC_SHOP_INFOS_BY_IDS } from "GraphQLQueries";
import { gaEvent, getOnlineUrl } from "Utils";

type Props = {
    currentUserSubscription: UserSubscriptionDTO;
    availableSubscriptionUpgrades: Subscription[];
    onModalClosed: () => void;
};

export const UserAccountSubscriptionUpgradeModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();
    const { currentUserSubscription, availableSubscriptionUpgrades } = modalContent;
    const allShopIds = new Set(flatMap(availableSubscriptionUpgrades, subscription => subscription.shopIds));

    const [selectedSubscription, setSelectedSubscription] = useState(currentUserSubscription.subscription);

    const [upgradeUserSubscription] = useMothershipMutation(UPGRADE_USER_SUBSCRIPTION);
    const { data: shopsData, loading: shopsLoading } = useMothershipQuery(GET_PUBLIC_SHOP_INFOS_BY_IDS, {
        variables: { shopIds: Array.from(allShopIds) }
    });

    const shopIdToPublicInfoMap =
        (!shopsLoading &&
            shopsData &&
            shopsData.getPublicShopInfosByIds.reduce(
                (acc: Record<string, PublicShopInfo>, publicShopInfo: PublicShopInfo) => {
                    acc[publicShopInfo.id] = publicShopInfo;
                    return acc;
                },
                {}
            )) ||
        {};

    const subscriptionSelected = (subscription: Subscription) => {
        setSelectedSubscription(subscription);
    };

    const performSubscriptionUpgrade = async (subscription: Subscription) => {
        gaEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.SUBSCRIPTION,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.UPGRADE
        });
        const { data } = await upgradeUserSubscription({
            variables: {
                userSubscriptionId: currentUserSubscription.id,
                targetSubscriptionId: selectedSubscription.id
            }
        });
        if (data && data.upgradeUserSubscription) {
            const {
                upgradeUserSubscription: { webPaymentResponse }
            } = data;
            if (!webPaymentResponse) {
                toast.error(`${translate("somethingWentWrong")} ${webPaymentResponse.errorText}`, {
                    draggable: false
                });
            } else {
                if (!!webPaymentResponse.redirectUrl) {
                    window.location.href = webPaymentResponse.redirectUrl;
                }
            }
        }
    };

    const currentSubscription = currentUserSubscription.subscription;
    const [currentPricePlan] = currentSubscription?.pricePlans || [];
    const hasSelectedUpgrade = !isEqual(selectedSubscription, currentSubscription);

    return (
        <Modal
            size="xs"
            open
            onClose={() => closeModal(modals.subscriptionUpgradeModal)}
            isScrolling
            overlayProps={{ zIndex: 1400 }}
        >
            <ModalHeader fontSize="2xl">
                <Center>{translate("chooseSubscriptionUpgrade")}</Center>
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody pt={2} px={0}>
                <Flex direction="column" key={currentSubscription.id} background={colors.gray[300]} py={3} px={6} m={0}>
                    <Text fontSize="xl" fontWeight="bold" as="span" alignSelf="center">
                        {translate("currentSubscription")}
                    </Text>
                    <Flex justifyContent="space-between">
                        <Text fontSize="xl" as="span" py={1}>
                            {currentSubscription.name}
                        </Text>
                        <Text fontSize="xl" as="span" py={1}>
                            {`${currentPricePlan.amount} kr / ${translate("mon")}`}
                        </Text>
                    </Flex>
                </Flex>
                <Center m={6}>
                    <Box size="22px" as={FaArrowDown} color={hasSelectedUpgrade ? colors.green[500] : "none"} />
                </Center>
                {availableSubscriptionUpgrades
                    .filter(sub => !sub.hidden)
                    .map(subscription => {
                        const [pricePlan] = subscription.pricePlans;
                        const isSelected = isEqual(subscription, selectedSubscription);
                        return (
                            <Flex
                                direction="column"
                                key={subscription.id}
                                onClick={() => subscriptionSelected(subscription)}
                                background={isSelected ? colors.green[200] : "none"}
                                border={isSelected ? `2px solid ${colors.green[500]}` : `2px solid ${colors.gray[400]}`}
                                borderRadius={4}
                                cursor="pointer"
                                p={4}
                                m={6}
                            >
                                <Flex justifyContent="space-between">
                                    <Text fontSize="xl" fontWeight="bold" as="span">
                                        {subscription.name}{" "}
                                    </Text>
                                    <Text fontSize="xl" as="span" fontWeight="bold">
                                        {`${pricePlan.amount} kr / ${translate("mon")}`}
                                    </Text>
                                </Flex>

                                {/* TODO: Expand to show shops and products */}
                                <Collapsable open={isSelected}>
                                    <Box mt={4}>
                                        <Stack stretch={8} flex="1">
                                            <Stack stretch={6}>
                                                <Box>
                                                    <LanguageText
                                                        tid="description"
                                                        mb={1}
                                                        fontSize="sm"
                                                        fontWeight="bold"
                                                        textTransform="uppercase"
                                                        letterSpacing="1px"
                                                    />
                                                    <Text fontSize="lg" mt={4}>
                                                        {/** @ts-ignore */}
                                                        <Truncate showExpand={true} lines={2}>
                                                            {subscription.description}
                                                        </Truncate>
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                                                        {translate("including")} {translate("products")} (
                                                        {subscription.subscriptionProducts.length})
                                                    </Text>
                                                    <SubscriptionProducts
                                                        subscriptionProducts={subscription.subscriptionProducts}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                                                        {translate("including")} {translate("restaurants")} (
                                                        {subscription.shopIds.length})
                                                    </Text>
                                                    <ExpandableList
                                                        isLoading={shopsLoading}
                                                        items={
                                                            shopsLoading
                                                                ? []
                                                                : subscription.shopIds.map(
                                                                      shopId => shopIdToPublicInfoMap[shopId]
                                                                  )
                                                        }
                                                        renderItem={publicShopInfo => (
                                                            <Text as="span" fontSize="lg">
                                                                <Link
                                                                    color="blue.500"
                                                                    as="a"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            getOnlineUrl(
                                                                                publicShopInfo.name,
                                                                                publicShopInfo.publicId
                                                                            ),
                                                                            "_blank",
                                                                            "noopener"
                                                                        )
                                                                    }
                                                                >
                                                                    {publicShopInfo.name}
                                                                </Link>
                                                            </Text>
                                                        )}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Collapsable>
                            </Flex>
                        );
                    })}
            </ModalBody>
            <ModalActions display="flex" justifyContent="space-between">
                <Button
                    themeColor="red"
                    variant="outline"
                    mr={4}
                    onClick={() => {
                        closeModal(modals.subscriptionUpgradeModal);
                    }}
                >
                    {translate("back")}
                </Button>
                <Button
                    themeColor="green"
                    onClick={() => performSubscriptionUpgrade(selectedSubscription)}
                    disabled={isEqual(currentUserSubscription.subscription, selectedSubscription)}
                >
                    {translate("continue")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
