import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik-next";
import { AiOutlinePauseCircle } from "@react-icons/all-files/ai/AiOutlinePauseCircle";

import {
    Modal,
    ModalHeader,
    ModalBody,
    FormInput,
    LoadingDots,
    CircularProgress,
    Alert,
    AlertIcon,
    AlertTitle
} from "Molecules";
import { Box, Button, Center, Flex, Text, Textarea } from "Atoms";
import { useOrderWindowSocketStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import { IModalContext, useLanguage } from "Providers";
import { LOCAL_STORAGE_CONSTANTS, modalNames } from "Constants";
import { useEffectOnce, useLocalStorage, useMothershipMutation } from "Hooks";
import { ActivePayingCustomerStatus, CustomerFeedbackType } from "Types";
import { UPSERT_CUSTOMER_FEEDBACK } from "GraphQLMutations";
import { getCleanGroupOrderNickname } from "../utils";
import { FeedbackRating } from "../../../pages/customerFeedback/components/FeedbackRating";

type FormikValues = {
    text: string;
    overallRating: number;
};

export const GroupOnlineOrderPaymentLockModal: React.FC<IModalContext<{}>> = ({ closeModal }) => {
    const { activePayingCustomer, activePayingCustomerStatus, nickname, setHasSeenFeedback } =
        useOrderWindowSocketStore();
    // Don't remove 'orderWindows', we need it for trigger zustand updates
    const { getActiveOrderWindow, orderWindows } = useOrderWindowsStore();
    const { selectedShop } = useQoplaStore();

    const { translate, translateWithArgument } = useLanguage();

    const [addCustomerFeedback] = useMothershipMutation(UPSERT_CUSTOMER_FEEDBACK);

    const [hasSentFeedback, setHasSentFeedback] = useLocalStorage(
        LOCAL_STORAGE_CONSTANTS.GROUP_ORDER_HAS_SENT_FEEDBACK,
        false
    );

    const MAX_NUM_FEEDBACK_CHARS = 200;

    const isAborted = activePayingCustomerStatus === ActivePayingCustomerStatus.ABORTED;
    const isCompleted = activePayingCustomerStatus === ActivePayingCustomerStatus.COMPLETED;
    const isInProgress = activePayingCustomerStatus === ActivePayingCustomerStatus.STARTED;

    const onCloseModal = () => closeModal(modalNames.GROUP_ONLINE_ORDER_PAYMENT_LOCK_MODAL);

    useEffectOnce(() => {
        setHasSeenFeedback(true);
    });

    useEffect(() => {
        if (activePayingCustomer === nickname) {
            onCloseModal();
        }
    }, [activePayingCustomer]);

    const validationSchema = Yup.object().shape(
        {
            text: Yup.string().when("overallRating", {
                is: val => val === 0,
                then: Yup.string().max(
                    MAX_NUM_FEEDBACK_CHARS,
                    translateWithArgument("formErrorMaxNoCharacters", MAX_NUM_FEEDBACK_CHARS)
                ),
                otherwise: Yup.string()
            }),
            overallRating: Yup.number().when("text", {
                is: val => !val,
                then: Yup.number().min(1),
                otherwise: Yup.number()
            })
        },
        [["text", "overallRating"]]
    );

    const handleSubmitFeedback = async (values: FormikValues) => {
        try {
            if (!!values.text || values.overallRating > 0) {
                const orderWindow = getActiveOrderWindow();

                await addCustomerFeedback({
                    variables: {
                        customerFeedback: {
                            shopId: selectedShop?.id,
                            orderId: orderWindow?.postponeOrderId,
                            additionalComments: values.text,
                            name: getCleanGroupOrderNickname(nickname as string),
                            type: CustomerFeedbackType.GROUP_ORDER,
                            overallRating: values.overallRating
                        }
                    }
                });
                setHasSentFeedback(true);
            } else {
                onCloseModal();
            }
        } catch (error) {
            console.error("Error when submitting group order feedback", error);
        }
    };

    const headerText = isAborted
        ? translate("canceledTheirPurchase")
        : isCompleted
        ? translate("paidTheirPurchase")
        : translate("isPayingTheirPurchase");

    return (
        <Modal open>
            <ModalHeader fontSize="2xl" pb={0} display="flex" alignItems="center" flexWrap="wrap">
                <Box as={AiOutlinePauseCircle} size="30px" color="orange.500" mr={2} />
                <Text as="span" textTransform="capitalize" mr={1}>
                    {getCleanGroupOrderNickname(activePayingCustomer ?? "")}
                </Text>
                <Text as="span" display="inline-flex">
                    {headerText}
                    {isInProgress && <LoadingDots numberOfDots={3} />}
                </Text>
            </ModalHeader>
            <ModalBody>
                <Text fontSize="lg" mb={3}>
                    {translate("groupOnlineOrderPaymentLockExplanation")}
                </Text>
                {hasSentFeedback ? (
                    <Center h="full" w="full" direction="column">
                        <Alert
                            color="gray.800"
                            rounded="lg"
                            zIndex="1500"
                            status="success"
                            variant="subtle"
                            justifyContent="center"
                            textAlign="center"
                            height="100%"
                            width="100%"
                        >
                            <AlertIcon size="40px" mr={0} />
                            <AlertTitle ml={2} fontSize="2xl">
                                {translate("thanksForFeedback")}
                            </AlertTitle>
                        </Alert>
                        <Button
                            size="lg"
                            fullWidth
                            themeColor="blue"
                            rounded="lg"
                            onClick={onCloseModal}
                            isDisabled={isInProgress}
                            mt={6}
                        >
                            {translate("close")}
                        </Button>
                    </Center>
                ) : (
                    <Box>
                        <Text color="gray.700" mb={6}>
                            {translate("whyNotGiveFeedback")}
                        </Text>
                        <Formik
                            validationSchema={validationSchema}
                            validateOnMount
                            initialValues={{ text: "", overallRating: 0 }}
                            onSubmit={handleSubmitFeedback}
                        >
                            {({ values, isSubmitting, isValid, setFieldValue }) => {
                                const textLength = values.text.length;
                                const isAtMax = textLength >= MAX_NUM_FEEDBACK_CHARS;
                                const circularValue = isAtMax ? 100 : (textLength * 100) / MAX_NUM_FEEDBACK_CHARS;

                                const isDisabled = !isValid;

                                return (
                                    <Form>
                                        <Box mb={4}>
                                            <FormInput
                                                wrapperProps={{ mb: 2 }}
                                                fullWidth
                                                resize="none"
                                                component={Textarea}
                                                name="text"
                                                isMandatory
                                                rounded="lg"
                                                placeholder={translate("groupOrderFeedbackPlaceholder")}
                                            />
                                            <Flex justify="flex-end">
                                                <CircularProgress
                                                    value={circularValue}
                                                    size="23px"
                                                    progressColor={isAtMax ? "red" : "blue"}
                                                    trackIsRound
                                                />
                                            </Flex>
                                        </Box>
                                        <FeedbackRating
                                            size="md"
                                            mb={6}
                                            justify="center"
                                            onSetOverallRating={rating => setFieldValue("overallRating", rating)}
                                        />

                                        <Button
                                            size="lg"
                                            fullWidth
                                            themeColor="blue"
                                            rounded="lg"
                                            type="submit"
                                            isLoading={isSubmitting}
                                            isDisabled={isDisabled}
                                            loadingText={translate("sending")}
                                        >
                                            {translate("send")}
                                        </Button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                )}
            </ModalBody>
        </Modal>
    );
};
