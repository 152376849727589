import React from "react";

import { Stack, Text } from "Atoms";
import { FullOrderWindowCustomer, useLanguage } from "Providers";
import { LoadingDots } from "Molecules";
import { getOrderWindowCustomersReadyCounts } from "../utils";

type Props = {
    customers: FullOrderWindowCustomer[];
    isInGroupOrder: boolean;
    isLastToOrder: boolean;
};

export const GroupOnlineOrderCustomersStatuses: React.FC<Props> = ({ customers, isInGroupOrder, isLastToOrder }) => {
    const { translate } = useLanguage();

    if (!isInGroupOrder) {
        return null;
    }

    if (isLastToOrder) {
        return (
            <Text display="flex" as="span" fontSize="lg" color="gray.600" fontWeight={700}>
                {translate("waitingForYou")}
                <LoadingDots numberOfDots={3} />
            </Text>
        );
    }

    const { notReadyCount, readyCount } = getOrderWindowCustomersReadyCounts(customers);

    return (
        <Stack stretch={4} isInline>
            <Stack isInline stretch={2}>
                <Text as="span" fontSize="lg" color="gray.600" fontWeight={700}>
                    {translate("ready")}: {readyCount}
                </Text>
            </Stack>
            <Stack isInline stretch={2}>
                <Text display="flex" as="span" fontSize="lg" color="gray.600" fontWeight={700}>
                    {translate("notReady")}: {notReadyCount}
                </Text>
            </Stack>
        </Stack>
    );
};
