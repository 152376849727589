import React, { useEffect } from "react";

import { Box, Button, Flex, Image, Link, Text } from "Atoms";
import { SubscriptionStatus, SubscriptionType, UserSubscriptionDTO } from "Types";
import { UserAccountSubscriptionStatusLabel } from "./UserAccountSubscriptionStatusLabel";
import { GET_GROUP_SUBSCRIPTION_CONTACT_INFORMATION_BY_USER_SUBSCRIPTION_ID } from "GraphQLQueries";
import { useMothershipLazyQuery } from "Hooks";
import { useLanguage } from "Providers";

type Props = {
    userSubscription: UserSubscriptionDTO;
    onOpenDrawer: () => void;
};

export const UserAccountSubscriptionCard: React.FC<Props> = ({ userSubscription, onOpenDrawer }) => {
    const [fetchGroupSubscriptionContactInformation, { data: groupSubscriptionContactInformation }] =
        useMothershipLazyQuery(GET_GROUP_SUBSCRIPTION_CONTACT_INFORMATION_BY_USER_SUBSCRIPTION_ID, {
            fetchPolicy: "network-only"
        });
    const { translate } = useLanguage();

    const isGroupSubscription = userSubscription.subscriptionType == SubscriptionType.GROUP;
    const contactEmail =
        groupSubscriptionContactInformation?.getGroupSubscriptionContactInformationByUserSubscriptionId?.contactEmail;

    const { subscription } = userSubscription;
    const hasImage = !!subscription.imageUrl;

    useEffect(() => {
        if (isGroupSubscription) {
            fetchGroupSubscriptionContactInformation({ variables: { userSubscriptionId: userSubscription.id } });
        }
    }, []);

    const onButtonClick = () => {
        if (userSubscription.status === SubscriptionStatus.ACTIVE) {
            onOpenDrawer();
        }
    };

    return (
        <Button
            key={subscription.id}
            onClick={onButtonClick}
            h="full"
            p="0"
            _hover={{ cursor: "pointer" }}
            bg="white"
            boxShadow="md"
            rounded="lg"
        >
            <Flex direction="column" h="auto" w="full">
                {hasImage && <Image src={subscription.imageUrl} maxH="150px" objectFit="cover" roundedTop="lg" />}
                <Box p={4}>
                    <Flex h="auto" align="center" justify="space-between">
                        <Flex direction="column" align="flex-start">
                            <UserAccountSubscriptionStatusLabel userSubscription={userSubscription} />
                            <Text fontSize="xl" fontWeight="bold" mb="0">
                                {subscription!.name}
                            </Text>
                            {isGroupSubscription && contactEmail && (
                                <Text fontSize="sm" fontWeight="normal" mb={2}>
                                    {translate("contactInformation")}:
                                    <Link href={`mailto:${contactEmail}`} color="blue.500" ml={1}>
                                        {contactEmail}
                                    </Link>
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </Button>
    );
};
