import React from "react";
import { GiReceiveMoney } from "@react-icons/all-files/gi/GiReceiveMoney";
import { CgBrowser } from "@react-icons/all-files/cg/CgBrowser";

import { Flex } from "Atoms";
import { useLanguage } from "Providers";
import { IconTextWrapper } from "Molecules";

export const GiftCardLegal = ({ ...rest }) => {
    const { translate } = useLanguage();

    return (
        <Flex direction="column" justifyContent="center" my={8} mx="auto" height="auto" {...rest}>
            <IconTextWrapper text={translate("giftCardMoney")} icon={GiReceiveMoney} />
            <IconTextWrapper text={translate("giftCardUsage")} icon={CgBrowser} />
        </Flex>
    );
};
