import React, { useState } from "react";
import { ImCross } from "@react-icons/all-files/im/ImCross";
import { TiTick } from "@react-icons/all-files/ti/TiTick";

import { UserGiftCard, GiftCard } from "Types";
import { UserGiftCardInformation } from "../../../giftCardStatusContainer/components/UserGiftCardInformation";
import { Flex, Button, Box, Input, Grid, Label } from "Atoms";
import { useLanguage, useAuthUser } from "Providers";
import { IconButton, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseBtn } from "Molecules";
import { GiftCardLegal } from "./GiftCardLegal";
import { IncludedShops } from "../../../giftCardStatusContainer/components/IncludedShops";

type Props = {
    userGiftCard: UserGiftCard;
    giftCardSettings: GiftCard;
    handleAcceptGiftCard: (giftCardId: string) => void;
    closeModal: () => void;
};

/**
 * Function to send in email and mask parts of it
 * @description if the first part of the email (before @ symbol)
 * is a longer length than 3 characters it will present the first and last letter of that part
 * with the middle letters hidden
 * @param {string} emailAddress
 * @returns {string} e.g someone@qopla.com will become s*****e@qopla.com
 */
const maskEmail = (emailAddress: string) => {
    if (!emailAddress) {
        return "";
    }
    const split = emailAddress.split("@");
    const firstPart = split[0];
    const domain = split[1];
    return (
        Array.from(firstPart).reduce((value: string, currentValue: string, index: number) => {
            if (firstPart.length <= 3) {
                return (value += "*");
            }
            if (index === 0 || firstPart.length - 1 === index) {
                value += currentValue;
            } else {
                value += "*";
            }

            return value;
        }, "") +
        "@" +
        domain
    );
};

export const GiftCardModal: React.FC<Props> = ({
    userGiftCard,
    giftCardSettings,
    handleAcceptGiftCard,
    closeModal
}) => {
    const { translate } = useLanguage();
    const { userAccount } = useAuthUser();
    const [confirmEmail, setConfimEmail] = useState<string>("");

    const userAccountEmail = userAccount?.contactInformation.email;
    const recipientEmail = userGiftCard?.recipientDetails?.recipientEmail;

    const showConfirmEmail = userAccountEmail !== recipientEmail;

    let emailMatches = !showConfirmEmail;
    const confirmEmailText = `** ${translate("confirmRecipientEmail")}`;
    if (!emailMatches) {
        emailMatches =
            confirmEmail.toLocaleLowerCase() === userGiftCard?.recipientDetails?.recipientEmail.toLocaleLowerCase();
    }

    const giftCardSettingsWithShopSettings = {
        ...giftCardSettings,
        shopName: "",
        shopImageUrl: ""
    };

    return (
        <Modal open={true} maxW="45rem" rounded="md" isScrolling onClose={closeModal} maxHeight="90vh">
            <ModalCloseBtn onClick={closeModal} zIndex={1500} />
            <ModalBody maxHeight="100%">
                <Box pt={3} h="100%">
                    <UserGiftCardInformation
                        userGiftCard={userGiftCard}
                        giftCardSettings={giftCardSettingsWithShopSettings}
                    />
                    <GiftCardLegal pl="1.5rem" />
                    <IncludedShops giftCardSettings={giftCardSettings} width="100%" isClickable={false} />
                    {showConfirmEmail && (
                        <Label mt={8} background="none" size="lg" pl={0} fontWeight="bold">
                            {confirmEmailText}
                            {` - ( ${maskEmail(recipientEmail)} )`}
                        </Label>
                    )}
                    <Flex justifyContent="flex-end" mt={showConfirmEmail ? 0 : 8} alignItems="center">
                        {showConfirmEmail && (
                            <InputGroup width="100%">
                                <Input
                                    size="lg"
                                    mr={4}
                                    border="1px"
                                    borderColor={emailMatches ? "green.500" : "red.500"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setConfimEmail(e.target.value)
                                    }
                                ></Input>
                                <InputRightElement width="5rem">
                                    <IconButton
                                        size="sm"
                                        ml={2}
                                        w="2.5rem"
                                        h="2.5rem"
                                        icon={emailMatches ? TiTick : ImCross}
                                        themeColor={emailMatches ? "green" : "red"}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        )}
                        <Button
                            size="lg"
                            variant="outline"
                            color="green.700"
                            borderColor="green.400"
                            disabled={!emailMatches}
                            ml={6}
                            onClick={() => {
                                handleAcceptGiftCard(userGiftCard.id ?? "");
                            }}
                        >
                            {translate("claim")}
                        </Button>
                    </Flex>
                </Box>
            </ModalBody>
        </Modal>
    );
};
