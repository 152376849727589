import React from "react";

import { FullOrderWindowCustomer, getFullOrderWindowCustomersFromOrderWindow, useLanguage } from "Providers";
import { useOrderWindowsStore } from "Stores";
import { Box, Flex, Stack, Text } from "Atoms";
import { CustomerMetaStatus, OrderWindow } from "Types";
import { GroupOnlineCustomerSummary } from "./components/GroupOnlineCustomerSummary";
import { sortOrderWindowCustomers } from "../../utils/orderWindowCustomersHelpers";

type Props = {
    onSingleCustomerPay?: (nickname: string) => void;
    statusToShow?: CustomerMetaStatus;
    showPayment?: boolean;
    isInGroupOrder?: boolean;
};

export const GroupOnlineOrderSummary: React.FC<Props> = ({
    onSingleCustomerPay,
    statusToShow = CustomerMetaStatus.ORDERED,
    showPayment = false,
    isInGroupOrder = false
}) => {
    // Don't remove 'orderWindows', we need it for trigger zustand updates
    const { orderWindows, getActiveOrderWindow } = useOrderWindowsStore();
    const { translate } = useLanguage();

    const orderWindow = getActiveOrderWindow();

    const orderWindowCustomers = getFullOrderWindowCustomersFromOrderWindow(orderWindow as OrderWindow) ?? [];

    const customers = sortOrderWindowCustomers(orderWindowCustomers) as FullOrderWindowCustomer[];

    return (
        <Box>
            <Flex flex="1" direction="column" rounded="lg" h="auto">
                <Text textAlign="center" fontSize="lg" color="gray.700" fontWeight="700">
                    {translate("yourGroup")}
                </Text>
                <Stack stretch={8} flex="1">
                    {customers.map(customer => (
                        <GroupOnlineCustomerSummary
                            key={customer.nickname}
                            showPayment={showPayment}
                            statusToShow={statusToShow}
                            customer={customer}
                            isInGroupOrder={isInGroupOrder}
                            onPayClick={onSingleCustomerPay}
                        />
                    ))}
                </Stack>
            </Flex>
        </Box>
    );
};
