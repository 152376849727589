import { FullOrderWindowCustomer } from "Providers";
import { orderWindowSocketStore } from "Stores";
import { OrderWindowCustomerStatus, OrderWindowCustomer } from "Types";
import { removeAfterChar } from "Utils";

export const getUnReadyOrderWindowCustomersCount = (customers: FullOrderWindowCustomer[]) => {
    const { groupOrderNicknames } = orderWindowSocketStore.getState();

    return customers
        .filter(customer => groupOrderNicknames.includes(customer.nickname))
        .filter(customer => customer.status !== OrderWindowCustomerStatus.READY_TO_ORDER).length;
};

export const getReadyOrderWindowCustomersCount = (customers: FullOrderWindowCustomer[]) => {
    const { groupOrderNicknames } = orderWindowSocketStore.getState();

    return customers
        .filter(customer => groupOrderNicknames.includes(customer.nickname))
        .filter(customer => customer.status === OrderWindowCustomerStatus.READY_TO_ORDER).length;
};

export const getOrderWindowCustomersReadyCounts = (customers: FullOrderWindowCustomer[]) => {
    const unReadyOrderWindowCustomersCount = getUnReadyOrderWindowCustomersCount(customers);
    const readyOrderWindowCustomersCount = getReadyOrderWindowCustomersCount(customers);

    return {
        notReadyCount: unReadyOrderWindowCustomersCount,
        readyCount: readyOrderWindowCustomersCount
    };
};

export const getCleanGroupOrderNickname = (nickname: string) => {
    return removeAfterChar(nickname, "#");
};

/**
 * Sorting logic for group ordering customers
 *
 * - Show me always at top
 * - Show disconnected customers at bottom
 *
 * - Sort nicknames alphabetically
 */

export const sortOrderWindowCustomers = (customers: OrderWindowCustomer[], shouldShowDisconnected: boolean = true) => {
    const { nickname, groupOrderNicknames } = orderWindowSocketStore.getState();

    /**
     * 0 - Me
     * 1 - Connected
     * 2 - Disconnected
     */

    const grouped = customers.reduce<[OrderWindowCustomer[], OrderWindowCustomer[], OrderWindowCustomer[]]>(
        (acc, customer) => {
            const meGroup = acc[0];
            const connectedGroup = acc[1];
            const disconnectedGroup = acc[2];

            const isMe = customer.nickname === nickname;
            const isConnected = [
                OrderWindowCustomerStatus.CONNECTED,
                OrderWindowCustomerStatus.READY_TO_ORDER
            ].includes(customer.status);
            const isDisconnected = customer.status === OrderWindowCustomerStatus.DISCONNECTED;

            if (isMe) {
                meGroup.push(customer);
            }

            if (isConnected && !isMe) {
                connectedGroup.push(customer);
            }

            if (isDisconnected && shouldShowDisconnected && !isMe) {
                disconnectedGroup.push(customer);
            }

            return acc;
        },
        [[], [], []]
    );

    const sortGroup = (group: OrderWindowCustomer[]) => {
        const isMeInGroupOrder = groupOrderNicknames.includes(nickname as string);
        const shouldShowReadyAtTop = isMeInGroupOrder;

        /**
         * If me is in group, we show ready customers at top.
         * Otherwise, we sort alphabetically
         */
        return group.sort((a, b) => {
            return shouldShowReadyAtTop && a.status === OrderWindowCustomerStatus.READY_TO_ORDER
                ? -1
                : 1 || a.nickname.localeCompare(b.nickname);
        });
    };

    grouped.forEach(group => sortGroup(group));

    const flat = grouped.flat();

    return flat;
};
