import React from "react";

import { GroupSubscriptionDTO } from "Types";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, ModalActions } from "Molecules";
import { Box, Button, Flex, Image, Stack, Text } from "Atoms";
import { IModalContext, useAuthUser, useLanguage } from "Providers";
import { modalNames } from "Constants";
import { SubscriptionProducts } from "../../../../../shared";
import { SubscriptionStatusContainerShops } from "../../../../subscriptionStatusContainer/components";
import { ACCEPT_GROUP_SUBSCRIPTION_INVITATION } from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { LanguageText } from "Components";
import { SubscriptionEnrollmentStatus } from "../UserAccountSubscriptions";

type Props = {
    groupSubscription: GroupSubscriptionDTO;
    onModalClosed: (subscriptionEnrollmentStatus: SubscriptionEnrollmentStatus) => void;
    isPreview: boolean;
};

export const UserAccountSubscriptionInviteModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();
    const { userAccount } = useAuthUser();
    const { groupSubscription, isPreview = false, onModalClosed } = modalContent;

    const [acceptGroupSubscriptionInvitation] = useMothershipMutation(ACCEPT_GROUP_SUBSCRIPTION_INVITATION);

    const onCloseModal = (subscriptionEnrollmentStatus: SubscriptionEnrollmentStatus) => {
        closeModal(modalNames.SUBSCRIPTION_INVITE_MODAL);
        onModalClosed && onModalClosed(subscriptionEnrollmentStatus);
    };

    const handleAcceptInvitation = async () => {
        const { data } = await acceptGroupSubscriptionInvitation({
            variables: {
                groupSubscriptionOrderDTO: {
                    userAccountId: userAccount?.id,
                    groupSubscriptionId: groupSubscription.id
                }
            }
        });

        const wasEnrolledSuccessful = !!data.acceptGroupSubscriptionInvitation ? "success" : "full";
        // TODO: Probably want to pop a success toast or something here  "Prenumeration tillagd"
        onCloseModal(wasEnrolledSuccessful);
    };

    const handleDeclineInvitation = () => {
        onCloseModal("decline");
    };

    return (
        <Modal size="xs" open onClose={() => onCloseModal("decline")} isScrolling overlayProps={{ zIndex: 1400 }}>
            <ModalHeader fontSize="2xl">
                {translate("goodNews")}!
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody pt={2}>
                <Flex direction="column">
                    <Image src={groupSubscription.subscription.imageUrl} rounded="md" objectFit="cover" maxH="200px" />
                    <Box mt={4}>
                        <Stack stretch={8} flex="1">
                            <Box>
                                <Text mb={2} fontSize="xl">
                                    <Text as="span" fontWeight="bold">
                                        {groupSubscription.company.name}{" "}
                                    </Text>
                                    <Text as="span">{translate("hasInvitedYouTo")} </Text>
                                    <Text as="span" fontWeight="bold">
                                        {" "}
                                        {groupSubscription.subscription.name}
                                    </Text>
                                    <Text as="span"> {translate("theSubscription")}!</Text>
                                </Text>
                                <Text fontSize="md">{groupSubscription.description}</Text>
                            </Box>
                            <Stack stretch={6}>
                                <Box>
                                    <LanguageText
                                        tid="description"
                                        mb={1}
                                        fontSize="sm"
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                        letterSpacing="1px"
                                    />
                                    <Text ml={2}>{groupSubscription.subscription.description}</Text>
                                </Box>
                                <Box>
                                    <Text
                                        mb={1}
                                        fontSize="sm"
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                        letterSpacing="1px"
                                    >
                                        {translate("including")} {translate("products")}{" "}
                                    </Text>
                                    <SubscriptionProducts
                                        subscriptionProducts={groupSubscription.subscription.subscriptionProducts}
                                    />
                                </Box>
                                <Box>
                                    <Text
                                        mb={1}
                                        fontSize="sm"
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                        letterSpacing="1px"
                                    >
                                        {translate("including")} {translate("restaurants")}{" "}
                                    </Text>
                                    <SubscriptionStatusContainerShops ids={groupSubscription.subscription.shopIds} />
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Flex>
            </ModalBody>
            {!isPreview && (
                <ModalActions display="flex" justifyContent="flex-end">
                    <Button themeColor="red" variant="outline" mr={4} onClick={handleDeclineInvitation}>
                        {translate("decline")}
                    </Button>
                    <Button themeColor="blue" onClick={handleAcceptInvitation}>
                        {translate("accept")}
                    </Button>
                </ModalActions>
            )}
        </Modal>
    );
};
