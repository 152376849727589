import React, { FC } from "react";
import { isIOS } from "react-device-detect";
import { HiDotsVertical } from "@react-icons/all-files/hi/HiDotsVertical";

import { DrawerBody, DrawerContent, DrawerHeader, Drawer, DrawerOverlay, DrawerCloseButton } from "Organisms";
import { IDrawerComponentContext, useLanguage } from "Providers";
import { Box, Flex, Image, Text } from "Atoms";
import { LanguageText } from "Components";
import ShareIcon from "../../../../assets/images/AppleShare.png";

type Props = IDrawerComponentContext<{ isFromAdmin: boolean }>;

export const AddToHomeScreenDrawer: FC<Props> = ({ onCloseDrawer, props }) => {
    const { translate } = useLanguage();

    const key = props.isFromAdmin ? "addQoplaToHomeScreenAdmin" : "addQoplaToHomeScreenCustomer";

    return (
        <Drawer blockScrollOnMount={isIOS} open from="bottom" size="lg" onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    <DrawerCloseButton />
                </DrawerHeader>
                <DrawerBody pt={3}>
                    <LanguageText tid={key} textAlign="center" fontSize="lg" />
                    {isIOS ? (
                        <Flex color="gray.600" direction="column" align="center">
                            <Flex align="center">
                                <Text as="span">
                                    {translate("pressOnQoplaDotCom")} {translate("and")} {translate("tap")}
                                </Text>
                                <Image src={ShareIcon} maxW="25px" mx={1} />
                            </Flex>
                            <LanguageText tid="thenAddToHomeScreen" as="span" />
                        </Flex>
                    ) : (
                        <Flex align="center" justify="center" color="gray.600">
                            <LanguageText textTransform="capitalize" tid="tap" as="span" />
                            <Box as={HiDotsVertical} maxW="25px" mx={1} />
                            <LanguageText tid="thenAddToHomeScreen" as="span" />
                        </Flex>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
