import React from "react";
import { useQuery } from "react-apollo";

import { Image, ExpandableList, Text } from "Atoms";
import { GET_ALL_PRODUCT_NAMES_IDS_IMAGE_URLS } from "GraphQLQueries";
import { SubscriptionProducts as SubscriptionProductsType } from "Types";
import { useLanguage } from "Providers";

type Props = {
    subscriptionProducts: SubscriptionProductsType;
};

export const SubscriptionProducts: React.FC<Props> = ({ subscriptionProducts }) => {
    const { translate } = useLanguage();

    const ids = subscriptionProducts.map(product => product.refProductId);

    const { data, loading } = useQuery(GET_ALL_PRODUCT_NAMES_IDS_IMAGE_URLS, {
        variables: { ids }
    });

    const products: { name: string; id: string; imageUrl: string | null }[] = loading
        ? []
        : data.getAllProductNamesIdsImageUrls;

    const _products = products.map(product => {
        const foundSubscriptionProduct = subscriptionProducts.find(
            subscriptionProduct => subscriptionProduct.refProductId === product.id
        );
        const isFree = foundSubscriptionProduct?.percentageDiscount === 100;

        const discount = isFree
            ? translate("free")
            : foundSubscriptionProduct?.percentageDiscount
            ? `${foundSubscriptionProduct?.percentageDiscount}% ${translate("discount")}`
            : `${foundSubscriptionProduct?.amountDiscount} kr ${translate("discount")}`;

        return {
            id: product.id,
            name: product.name,
            discount,
            imageUrl: product.imageUrl,
            isFree
        };
    });

    _products.sort((a, b) => (a.isFree === b.isFree ? 0 : a.isFree ? -1 : 1));

    return (
        <ExpandableList
            items={_products}
            isLoading={loading}
            renderItem={product => {
                return (
                    <>
                        {product.imageUrl && (
                            <Image size="40px" rounded="lg" objectFit="cover" mr={3} src={product.imageUrl} />
                        )}
                        <Text fontSize="lg" m="0">
                            <Text as="span">{product.name}</Text>
                            <Text as="span" color={product.isFree ? "green.500" : "black"}>
                                {" "}
                                {product.discount}
                            </Text>
                        </Text>
                    </>
                );
            }}
        />
    );
};
