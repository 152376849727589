import React from "react";
import { toast } from "react-toastify";
{
    /** Leaving this here as it should be used if we move this under the router provider */
}
import { Link as RLink } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";

import { DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, Drawer, DrawerOverlay } from "Organisms";
import { useAuthUser, useLanguage, IDrawerComponentContext, UserAccount, UserTypes } from "Providers";
import { Flex, Link, Box, Button } from "Atoms";
import { LanguageHeader, LanguagePicker } from "Components";
import { LOGIN_USER_ACCOUNT_MUTATION } from "GraphQLMutations";
import { useTypedMutation } from "Hooks";
import { UserAccountLoginForm, UserAccountFormValues } from "./UserAccountLoginForm";
import { navigateToAccountRoute, isSubscriptionUrl, gaEvent } from "Utils";
import { GoogleAuthenticationButton } from "Organisms";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY, LinkType } from "Types";

interface ICustomerDrawer {
    onSuccessfulLoginCallback?: (userAccount: UserAccount) => void;
    onCloseDrawerCallback: () => void;
}

type Props = IDrawerComponentContext<ICustomerDrawer>;

//TODO: currently this is using window.location - needs to be looked into
/**
 * I have added router props to the drawer to be able to pass in navigate function from useNavigate
 * There is also the location in there as well - should swap out window.location for this later improvement
 */
export const CustomerDrawer: React.FC<Props> = ({ onCloseDrawer, props, routerProps }) => {
    const { translate } = useLanguage();
    const { navigate } = routerProps || {};
    const location = window.location;

    const { setAuthUserAndToken, userAccount, logout } = useAuthUser();

    const [loginUserAccount] = useTypedMutation(LOGIN_USER_ACCOUNT_MUTATION);

    const handleLogin = async (values: UserAccountFormValues) => {
        const { data } = await loginUserAccount({
            variables: { input: { email: values.email, password: values.password } }
        });

        if (data && data.loginUserAccount) {
            const [res] = data.loginUserAccount;
            if (res.path) {
                return res;
            } else {
                setAuthUserAndToken(UserTypes.USER_ACCOUNT, res);
                gaEvent({
                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.USER_ACCOUNT,
                    action: GOOGLE_ANALYTICS_EVENT_ACTION.LOGIN,
                    label: "Successful"
                });

                if (props.onSuccessfulLoginCallback) {
                    props.onSuccessfulLoginCallback(res);
                } else {
                    onCloseDrawer();
                }
            }
        } else {
            toast.error(translate("somethingWentWrong"));
        }
    };

    const logOutAndNavigate = (to: string) => {
        logout(to);
        !!navigate &&
            navigate("/admin", {
                state: {
                    from: location.pathname
                }
            });
        onCloseDrawer();
    };

    const navigateTo = (to: string) => {
        if (!!navigate) {
            navigate(to);
            onCloseDrawer();
        }
    };

    const _isSubscriptionUrl = isSubscriptionUrl(location.hostname);

    return (
        <Drawer open onClose={onCloseDrawer} onCloseCallback={props.onCloseDrawerCallback}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <LanguagePicker />
                </DrawerHeader>

                <DrawerBody pt={0}>
                    <Box>
                        {userAccount ? (
                            <>
                                <Button fullWidth onClick={() => navigateToAccountRoute(_isSubscriptionUrl)} mb={4}>
                                    {translate("profile")}
                                </Button>
                                <Button fullWidth onClick={() => logOutAndNavigate("/login")} mb={4}>
                                    {translate("logout")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <LanguageHeader tid="login" mb={8} size="lg" as="h3" />
                                <UserAccountLoginForm handleLogin={handleLogin} />
                                <GoogleAuthenticationButton setAuthUserAndToken={setAuthUserAndToken} />
                                <Flex mt={2} justify="space-between">
                                    <Link mt={4} as="a" color="blue.500" onClick={() => navigateTo("/forgot-password")}>
                                        {translate("forgotPassword")}
                                    </Link>
                                    <Link mt={4} color="blue.500" onClick={() => navigateTo("/signup")}>
                                        {translate("signup")}
                                    </Link>
                                </Flex>
                            </>
                        )}
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
